exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-case-studies-js": () => import("./../../../src/pages/en/case-studies.js" /* webpackChunkName: "component---src-pages-en-case-studies-js" */),
  "component---src-pages-en-companyinfo-js": () => import("./../../../src/pages/en/companyinfo.js" /* webpackChunkName: "component---src-pages-en-companyinfo-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-discover-hevelop-js": () => import("./../../../src/pages/en/discover-hevelop.js" /* webpackChunkName: "component---src-pages-en-discover-hevelop-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-method-js": () => import("./../../../src/pages/en/method.js" /* webpackChunkName: "component---src-pages-en-method-js" */),
  "component---src-pages-en-not-found-js": () => import("./../../../src/pages/en/not-found.js" /* webpackChunkName: "component---src-pages-en-not-found-js" */),
  "component---src-pages-en-our-figures-js": () => import("./../../../src/pages/en/our-figures.js" /* webpackChunkName: "component---src-pages-en-our-figures-js" */),
  "component---src-pages-en-partnership-js": () => import("./../../../src/pages/en/partnership.js" /* webpackChunkName: "component---src-pages-en-partnership-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-they-chose-us-js": () => import("./../../../src/pages/en/they-chose-us.js" /* webpackChunkName: "component---src-pages-en-they-chose-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-benefits-js": () => import("./../../../src/pages/it/benefits.js" /* webpackChunkName: "component---src-pages-it-benefits-js" */),
  "component---src-pages-it-campus-js": () => import("./../../../src/pages/it/campus.js" /* webpackChunkName: "component---src-pages-it-campus-js" */),
  "component---src-pages-it-careers-js": () => import("./../../../src/pages/it/careers.js" /* webpackChunkName: "component---src-pages-it-careers-js" */),
  "component---src-pages-it-case-studies-dmo-js": () => import("./../../../src/pages/it/case-studies/dmo.js" /* webpackChunkName: "component---src-pages-it-case-studies-dmo-js" */),
  "component---src-pages-it-case-studies-js": () => import("./../../../src/pages/it/case-studies.js" /* webpackChunkName: "component---src-pages-it-case-studies-js" */),
  "component---src-pages-it-case-studies-manfrotto-js": () => import("./../../../src/pages/it/case-studies/manfrotto.js" /* webpackChunkName: "component---src-pages-it-case-studies-manfrotto-js" */),
  "component---src-pages-it-case-studies-tessabit-js": () => import("./../../../src/pages/it/case-studies/tessabit.js" /* webpackChunkName: "component---src-pages-it-case-studies-tessabit-js" */),
  "component---src-pages-it-case-studies-trilab-js": () => import("./../../../src/pages/it/case-studies/trilab.js" /* webpackChunkName: "component---src-pages-it-case-studies-trilab-js" */),
  "component---src-pages-it-case-studies-vitamin-center-js": () => import("./../../../src/pages/it/case-studies/vitamin-center.js" /* webpackChunkName: "component---src-pages-it-case-studies-vitamin-center-js" */),
  "component---src-pages-it-ci-hanno-scelto-js": () => import("./../../../src/pages/it/ci-hanno-scelto.js" /* webpackChunkName: "component---src-pages-it-ci-hanno-scelto-js" */),
  "component---src-pages-it-companyinfo-js": () => import("./../../../src/pages/it/companyinfo.js" /* webpackChunkName: "component---src-pages-it-companyinfo-js" */),
  "component---src-pages-it-contattaci-js": () => import("./../../../src/pages/it/contattaci.js" /* webpackChunkName: "component---src-pages-it-contattaci-js" */),
  "component---src-pages-it-headquarters-js": () => import("./../../../src/pages/it/headquarters.js" /* webpackChunkName: "component---src-pages-it-headquarters-js" */),
  "component---src-pages-it-i-nostri-numeri-js": () => import("./../../../src/pages/it/i-nostri-numeri.js" /* webpackChunkName: "component---src-pages-it-i-nostri-numeri-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-it-metodo-js": () => import("./../../../src/pages/it/metodo.js" /* webpackChunkName: "component---src-pages-it-metodo-js" */),
  "component---src-pages-it-not-found-js": () => import("./../../../src/pages/it/not-found.js" /* webpackChunkName: "component---src-pages-it-not-found-js" */),
  "component---src-pages-it-partnership-js": () => import("./../../../src/pages/it/partnership.js" /* webpackChunkName: "component---src-pages-it-partnership-js" */),
  "component---src-pages-it-privacy-js": () => import("./../../../src/pages/it/privacy.js" /* webpackChunkName: "component---src-pages-it-privacy-js" */),
  "component---src-pages-it-report-js": () => import("./../../../src/pages/it/report.js" /* webpackChunkName: "component---src-pages-it-report-js" */),
  "component---src-pages-it-scopri-hevelop-js": () => import("./../../../src/pages/it/scopri-hevelop.js" /* webpackChunkName: "component---src-pages-it-scopri-hevelop-js" */),
  "component---src-pages-it-servizi-js": () => import("./../../../src/pages/it/servizi.js" /* webpackChunkName: "component---src-pages-it-servizi-js" */)
}

